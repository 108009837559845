@import 'variables';

.pizza-block {
  width: 100%;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
  margin-bottom: 35px;
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  @media (max-width: 1090px) {
    margin-bottom: 35px;
  }

  @media (max-width: 768px) {
    &:not(:last-of-type) {
      border-bottom: 1px solid #efefef;
      margin-bottom: 25px;
      padding-bottom: 25px;
    }
  }

  &__wrap-image {
    min-height: 250px;
    position: relative;
  }

  &__image {
    width: 260px;
    //border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 768px) {
      width: 250px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 1%;
    margin-bottom: 20px;
  }
  &__discr {
    text-align: left;
    min-height: 90px;
    font-size: 14px;
    @media (max-width: 767px) {
      min-height: auto;
    }
  }

  &__weight {
    float: right;
  }

  &__selector {
    display: flex;
    background-color: #f3f3f3;
    border-radius: 10px;
    flex-direction: column;
    padding: 20px;

    span {
      font-weight: 700;
    }

    ul {
      display: flex;
      flex: 1;

      &:first-of-type {
        margin-bottom: 6px;
      }

      li {
        padding: 8px;
        flex: 1;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        @include noselect();
        &.active {
          background: #ffffff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
          border-radius: 5px;
          cursor: auto;
        }
        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__price {
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.015em;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
}