@import 'variables';

.header {
  border-bottom: 1px solid $gray-line;
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 20px 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    &__cart {
      margin-top: 30px;
    }
  }

  &__logo {
    display: flex;

    img {
      margin-right: 15px;
      @media (max-width: 768px) {
        width: 90px;
        height: 50px;
      }
    }

    h1 {
      color: #181818;
      font-size: 24px;
      letter-spacing: 1%;
      text-transform: uppercase;
      font-weight: 800;
    }

    p {
      color: #7b7b7b;
    }
  }
}