@import 'variables';

.sort {
  margin-left: 20px;
  margin-top: 30px;
  position: relative;
  max-width: 290px;
  @media (max-width: 767px) {
    margin-left: 0;
    font-size: 13px;
  }
  &__label {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      transform: rotate(180deg);

      &.rotated {
        transform: rotate(0);
      }
    }

    b {
      margin-right: 8px;
    }

    span {
      color: #000;
      border-bottom: 1px dashed #000;
      cursor: pointer;
    }
  }

  &__popup {
    position: absolute;
    left: 90px;
    margin-top: 15px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    overflow: hidden;
    padding: 10px 0;
    width: 160px;
    z-index: 5;

    ul {
      overflow: hidden;
      li {
        padding: 12px 20px;
        cursor: pointer;

        &.active,
        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }

        &.active {
          font-weight: bold;
          color: #000;
        }
      }
    }
  }
}