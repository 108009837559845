@import 'fonts';
@import 'variables';
@import 'libs/normalize';

@import 'components/all';

body {
  background: #ffdf8c url(/img/bg.png);
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 20px 0;
    background: #ffdf8c;
  }
}

.wrapper {
  width: calc(100vw - 100px);
  height: 100%;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 10px;
  max-width: 1400px;
  @media (max-width: 767px) {
    width: calc(100% - 40px);
  }
}

.content {
  padding: 0;
  padding-bottom: 40px;

  &__title {
    margin: 35px 0;
    @media (max-width: 767px) {
      font-size: 32px;
      margin: 20px 0;
    }
  }

  &__items {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1vw;

    @media (max-width: 1380px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 1090px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__top {
    //display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
}

.container {
  width: $container-width;
  margin: 0 auto;

  &--cart {
    max-width: 820px;
    margin: 30px auto;
    .content__title {
      margin: 0;
    }

    @media (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 0;
    }

    .content__items {
      grid-template-columns: 1fr;
    }
  }
}

.cart {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      flex-direction: column;
      margin-top: 20px;

      .content__title {
        margin-bottom: 20px;
      }
    }
  }

  .content__title {
    display: flex;
    align-items: center;
    font-size: 32px;

    svg {
      position: relative;
      top: -2px;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      path {
        stroke: $black;
        stroke-width: 1.9;
      }
    }
  }

  &__clear {
    display: flex;
    align-items: center;
    cursor: pointer;
    @include noselect();

    span {
      display: inline-block;
      margin-left: 7px;
      color: #b6b6b6;
      font-size: 18px;
    }

    span,
    svg,
    path {
      transition: all $duration ease-in-out;
    }

    &:hover {
      svg {
        path {
          stroke: darken($color: #b6b6b6, $amount: 50);
        }
      }
      span {
        color: darken($color: #b6b6b6, $amount: 50);
      }
    }
  }

  &__item {
    display: flex;
    width: 100%;
    border-top: 1px solid $gray-line;
    padding-top: 15px;
    margin-top: 15px;

    @media (max-width: 767px) {
      flex-direction: column;
      position: relative;
    }

    &-img {
      display: flex;
      align-items: center;
      margin-right: 15px;
      width: 10%;

      @media (max-width: 767px) {
        width: 100%;
        justify-content: center;
        order: 1;
      }

      img {
        width: 80px;
        height: 80px;

        @media (max-width: 767px) {
          width: 60%;
          height: 60%;
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 40%;
      padding-right: 15px;
      @media (max-width: 992px) {
        width: 35%;
      }

      h3 {
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        letter-spacing: 0.01em;
      }

      p {
        font-size: 12px;
        color: #8d8d8d;
      }

      @media (max-width: 767px) {
        width: 100%;
        order: 2;
        h3 {
          font-size: 32px;
          margin-bottom: 8px;
          margin-top: 15px;
        }
      }
    }

    @media (max-width: 500px) {
      h3 {
        font-size: 19px;
      }
    }

    &-count {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 30%;
      @media (max-width: 992px) {
        width: 35%;
      }

      b {
        font-size: 27px;
      }

      @media (max-width: 767px) {
        order: 4;
        width: 90%;
        margin: 10px 0;

        .button {
          width: 40px;
          height: 35px;

          svg {
            width: 14px;
            height: 14px;
          }
        }

        b {
          font-size: 26px;
        }
      }

      &-minus {
        svg {
          path:first-of-type {
            display: none;
          }
        }
      }
    }

    &-price {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26%;
      @media (max-width: 767px) {
        width: 90%;
      }
      

      b {
        font-weight: bold;
        font-size: 27px;
        letter-spacing: 0.01em;
      }

      @media (max-width: 767px) {
        order: 3;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 10px 0;

        b {
          font-size: 26px;
        }
      }
    }

    &-remove {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 4%;

      @media (max-width: 767px) {
        position: absolute;
        right: 0;
        width: auto;

        button {
          width: 30px;
          height: 25px;
        }
      }

      .button {
        border-color: #222;
      }

      svg {
        transform: rotate(45deg);

        path {
          fill: #222;
        }
      }

      .button {
        svg {
          width: 11.5px;
          height: 11.5px;
          position: relative;
        }
        &:hover,
        &:active {
          border-color: #222;
          background-color: #fff;
        }
      }
    }
  }

  &__bottom {
    margin: 50px 0;

    &-details {
      display: flex;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-direction: column;
      }


      span {
        font-size: 22px;
        @media (max-width: 767px) {
          font-size: 18px;
        }

        &:last-of-type {
          b {
            color: #222;
            font-size: 28px;
          }
        }
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .go-back-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 210px;

        border-color: #222;

        span {
          color: #222;
          font-weight: 500;
          font-weight: 600;
        }

        &:hover {
          background-color: #fff;
          border-color: #222;

          span {
            color: #222;
          }
        }

        svg {
          margin-right: 12px;
          path {
            fill: transparent;
            stroke-width: 2;
          }
        }
      }

      .pay-btn {
        font-size: 16px;
        font-weight: 600;
        width: 210px;
        padding: 16px;
      }

      @media (max-width: 500px) {
        flex-direction: column;

        .button {
          margin-bottom: 20px;
          width: 100%;
          height: 54px;
        }
      }
    }
  }

  &--empty {
    margin: 0 auto;
    //width: 560px;
    text-align: center;

    h2 {
      font-size: 32px;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      line-height: 145.4%;
      letter-spacing: 0.01em;
      color: #777777;
    }

    i {
      font-style: normal;
      position: relative;
      top: 2px;
    }

    img {
      display: block;
      width: 300px;
      margin: 45px auto 60px;
      @media (max-width: 500px) {
        width: 100%;
      }
    }

    .button--black {
      padding: 12px 0 14px;
      width: 230px;
      margin: 0 auto;
      font-weight: 600;
      font-size: 18px;
    }
  }
}
.button-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  border: none;
  background: transparent url(/img/totop-black.png) center center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  height: 50px;
  width: 50px;
  z-index: 10;
  cursor: pointer;
  @media (max-width: 767px) {
    right: 5px;
    top: 100px;
    bottom: auto;
    height: 40px;
    width: 40px;
  }
}
.fix-cart {
  position: fixed;
  bottom: 50px;
  left: 50px;
  height: 50px;
  width: 50px;
  z-index: 10;
  background: transparent;
  cursor: pointer;
  @media (max-width: 767px) {
    left: 5px;
    top: 100px;
    bottom: auto;
    height: 40px;
    width: 40px;
    min-width: 70px;
    padding: 12px 15px;
  }
}
.menu-fixed {
  position: relative;
  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 20px;
    right: calc(5% + 20px);
    background: #fff;
    z-index: 99;
    padding-left: calc(5% - 2px);
  }
}
.show-plug {
  @media (max-width: 767px) {
    display: block;
    width: 90%;
    height: 72px;
  }
}